var site = site || {};

site.client = site.client || {};

(function ($) {
  Drupal.behaviors.gnavSecondaryFormatterV1 = {
    isMobile: false,
    nodes: {},
    selector: {
      siteContent: '.site-container',
      container: '.js-gnav-secondary-formatter-v1',
      secondaryNavWrapper: '.js-gnav-secondary-formatter-wrapper-v1',
      item: '.js-gnav-content-link',
      image: '.js-gnav-tout__image',
      link: '.js-gnav-tout__link',
      anchor: '.js-gnav-tout__anchor-link',
      headerWrapper: '.js-site-header__wrapper',
      headerInner: '.js-site-header-formatter-inner'
    },

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }

      if (!!Unison.fetch.now()) {
        self.isMobile = self.checkIfMobile();
      }

      var $containers = $(self.selector.container, context);
      $containers.each(function () {
        var $this = $(this);
        self.getDom($this);
        self.setSettings($this);
        self.setEvents($this);
        self.setup($this);
      });
      self.attached = true;
    },

    getDom: function ($that) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$secondaryNavWrapper = $(self.selector.secondaryNavWrapper, $that);
      $that.nodes.$items = $(self.selector.item, $that);
      $that.nodes.$images = $(self.selector.image, $that);
      $that.nodes.$links = $(self.selector.link, $that);
      $that.nodes.$anchors = $(self.selector.anchor, $that);
      $that.nodes.$header = $that
        .closest(self.selector.siteContent)
        .siblings(self.selector.headerWrapper);
      $that.nodes.$headerInner = $that.nodes.$header.find(self.selector.headerInner);
    },

    setup: function ($that) {
      var self = this;
      if (!$that.settings.hasImages) {
        self.initialiseLink($that);
      }
    },

    initialiseLink: function ($that) {
      var self = this;
      var hash = window.location.hash;
      var $anchor = $that.nodes.$anchors.filter('[href="' + hash + '"]');
      if (!!hash && $anchor.length > 0) {
        self.setActiveLink($that, $anchor.parent());
      } else {
        self.setFirstActive($that);
      }
    },
    setActiveLink: function ($that, $link) {
      $that.nodes.$items.removeClass('active');
      $link.addClass('active');
    },
    setEvents: function ($that) {
      var self = this;

      Unison.on('change', function () {
        self.isMobile = self.checkIfMobile();
        if ($that.is(':visible')) {
          self.setSettings($that);
          self.setupLayout($that);
        }
      });

      self.initStickyNav($that);
      if (!$that.settings.hasImages) {
        $that.nodes.$items.once().on('click', function () {
          var $selectedLink = $(this);
          self.setActiveLink($that, $selectedLink);
        });
      }
      $that.on('touchmove', function (e) {
        $that.addClass('slick-sliding');
      });
    },

    setSettings: function ($that) {
      var self = this;

      $that.settings = {};
      $that.settings.isSticky = self.isMobile ? $that.data('mobileSticky') : $that.data('sticky');
      $that.settings.hasImages = $that.nodes.$images.length > 0;
      $that.settings.direction = $('html').attr('dir') || 'ltr';
      $that.settings.breakpoints = $that.data('breakpoints') || {};
      $that.settings.sliderConfig = $that.data('sliderConfig') || {};
      $that.settings.sliderConfig.dots = $that.settings.sliderConfig.dots > 0 ? true : false;
      $that.settings.sliderConfig.arrows = $that.settings.sliderConfig.arrows > 0 ? true : false;
      $that.settings.sliderConfig.infinite =
        $that.settings.sliderConfig.infinite > 0 ? true : false;

      if (!!$that.settings.sliderConfig && !!$that.settings.sliderConfig.peeking) {
        self.setPeaking($that);
      }
    },

    setPeaking: function ($that) {
      $that.settings.sliderConfig.peekingPercent = {};
      Object.keys($that.settings.breakpoints).forEach(function (key) {
        $that.settings.breakpoints[key] = parseInt($that.settings.breakpoints[key]);
        if (
          typeof $that.settings.sliderConfig.peeking[key] === 'string' &&
          $that.settings.sliderConfig.peeking[key].indexOf('.') === 0
        ) {
          $that.settings.sliderConfig.peekingPercent[key] =
            ((100 / $that.settings.breakpoints[key]) *
              parseInt($that.settings.sliderConfig.peeking[key].replace('.', ''))) /
            100;
        }
      });
    },

    getBreakpoint: function () {
      var width = parseInt(Unison.fetch.now().width, 10);
      if (width <= parseInt(Unison.fetch.all()['usn-medium'], 10)) {
        return 'mobile';
      }
      if (width <= parseInt(Unison.fetch.all()['usn-xxx-large'], 10)) {
        return 'desktop';
      }
      if (width <= parseInt(Unison.fetch.all()['usn-xwide'], 10) > 0) {
        return 'large';
      }
      return 'extra_large';
    },

    togglePeekingClasses: function ($that) {
      var self = this;
      if ($that.settings.sliderConfig.length < 1 || !$that.settings.sliderConfig.peekingPercent) {
        return;
      }
      var direction = $that.settings.direction === 'ltr' ? 'right' : 'left';
      var breakpoint = self.getBreakpoint();
      var hasSlides = $that.settings.breakpoints[breakpoint] < $that.nodes.$items.length;
      var percent = $that.settings.sliderConfig.peekingPercent[breakpoint] || 0;
      if (percent > 0 && hasSlides) {
        percent = self.closestNumber(percent, 5) || 5;
        $that.find('.slick-list').addClass('padding-' + direction + '-' + percent);
        $that.trigger('slider-peeking-changed');
      }
    },

    // Find closest number to n divisible by m
    closestNumber: function (n, m) {
      let q = parseInt(n / m);
      let n1 = m * q;
      let n2 = n * m > 0 ? m * (q + 1) : m * (q - 1);
      if (Math.abs(n - n1) < Math.abs(n - n2)) {
        return n1;
      } else {
        return n2;
      }
    },

    makeImageClickable: function ($this, href) {
      var self = this;
      var currentImageEl = $this.closest(self.selector.item).find(self.selector.image);
      currentImageEl.wrap(function () {
        return "<a href='" + href + "'></a>";
      });
    },

    setupLayout: function ($that) {
      var self = this;

      if (self.isMobile) {
        self.initSlider($that);
      } else {
        $that.nodes.$secondaryNavWrapper.hasClass('slick-initialized')
          ? $that.nodes.$secondaryNavWrapper.unslick()
          : null;
      }

      if (!$that.settings.hasImages) {
        $that.addClass('gnav-secondary--without-images');
        $that.css({ top: '' });
      }

      self.overlapNav($that);
      $that.data('bottom-margin') ? self.addExtraMargin($that) : null;
    },

    initSlider: function ($that) {
      var self = this;
      var $activeItem = $that.nodes.$items.filter('.active');
      $that.nodes.$secondaryNavWrapper.each(function () {
        var $this = $(this);
        var hasPeeking =
          !!$that.settings.sliderConfig && !!$that.settings.sliderConfig.peekingPercent;
        var $dotsDiv = $this.parent().find('.js-gnav-secondary-formatter__carousel-dots');
        var isRTL = site.direction.isRTL && Drupal?.settings?.forceSlickRTL;

        var initialSlide = $activeItem.length > 0 ? $that.nodes.$items.index($activeItem) : 0;
        var settings = {
          appendDots: $dotsDiv,
          initialSlide: initialSlide,
          swipe: true,
          arrows: false,
          dots: $that.settings.sliderConfig.dots,
          draggable: true,
          rtl: isRTL,
          infinite:
            hasPeeking && $that.settings.sliderConfig.peekingPercent.mobile > 1
              ? true
              : $that.settings.sliderConfig.infinite,
          slidesToScroll: 2,
          responsive: [
            {
              breakpoint: 769,
              settings: {
                slidesToShow: $that.settings.breakpoints.desktop || 3
              }
            },
            {
              breakpoint: 391,
              settings: {
                slidesToShow: $that.settings.breakpoints.mobile || 2,
                variableWidth: !$that.settings.hasImages
              }
            }
          ],
          onInit: function (e, slick) {
            self.getDom($that);
            self.setEvents($that);

            self.togglePeekingClasses($that);
            $that.trigger('slider-initialised');
            if (initialSlide > 0) {
              setTimeout(function () {
                $this.slickGoTo(initialSlide);
              }, 50);
            }
          },
          onAfterChange: function () {
            $that.trigger('slider-changed--after');
            $that.removeClass('slick-sliding');
          }
        };

        // Init this carousel with our settings
        $this.slick(settings);
      });
    },

    overlapNav: function ($that) {
      var self = this;
      var overlapBy = $that.data('overlap') ? $that.data('overlap') : null;
      if (overlapBy) {
        var overlapCount = Math.round(($that.height() * overlapBy) / 100);
        $that.css({
          top: '-' + overlapCount + 'px',
          'margin-bottom': '-' + overlapCount + 'px'
        });
        if (self.isMobile) {
          var mobileBottomMargin = overlapCount * -1;
          $that.css({
            'margin-bottom': mobileBottomMargin + 'px'
          });
        }
      } else {
        self.isMobile
          ? $that.css({
              'margin-bottom': '0'
            })
          : null;
      }
    },

    addExtraMargin: function ($that) {
      var extraBottomMargin = $that.data('bottom-margin') ? $that.data('bottom-margin') : null;
      var initialBottomMargin = $that.css('margin-bottom');
      initialBottomMargin =
        initialBottomMargin && initialBottomMargin.split('px')
          ? initialBottomMargin.split('px')[0]
          : 0;
      if (extraBottomMargin) {
        initialBottomMargin = parseInt(initialBottomMargin);
        var totalMargin = initialBottomMargin + extraBottomMargin;
        $that.css({
          'margin-bottom': totalMargin + 'px'
        });
      }
    },

    setFirstActive: function ($that) {
      $that.nodes.$items.length > 0 && $that.nodes.$links.length > 0
        ? $that.nodes.$links.first().closest($that.nodes.$items).addClass('active')
        : null;
    },

    initStickyNav: function ($that) {
      var self = this;
      var lastScrollTop = 0;
      var secondaryNavInitialPos = $that.offset().top;
      $(window).scroll(
        _.debounce(function () {
          if ($that.nodes.$items.length > 0 && $that.settings.isSticky) {
            var scrollTop = $(this).scrollTop();
            if (scrollTop > lastScrollTop) {
              self.setSticky($that);
            } else {
              self.setSticky($that);
              self.updatePosition(secondaryNavInitialPos, $that);
            }
            lastScrollTop = scrollTop;
          }
        }, 10)
      );
    },
    setSticky: function ($that) {
      var secondaryNavPos = $that.offset().top;

      if ($(window).scrollTop() > secondaryNavPos) {
        $that.addClass('gnav-secondary-formatter-sticky');
      }
      $that.css({ top: '' });
    },
    updatePosition: function (secondaryNavInitialPos, $that) {
      var headerInnerHeight = $that.nodes.$headerInner.outerHeight(true);
      var scrollTop = $(window).scrollTop();
      if (scrollTop <= secondaryNavInitialPos) {
        $that.removeClass('gnav-secondary-formatter-sticky');
        $that.css({ top: '' });
      } else {
        $that.css({ top: headerInnerHeight + 'px' });
      }
    },

    checkIfMobile: function () {
      var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
      var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

      return currentBpWidth <= bPlargeUp;
    },

    attached: false
  };
})(jQuery);
